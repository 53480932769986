import logo12WeekUpTo from './svg/Logo_12week_UPTO_1asterisk_en.svg';
import logo12Weeks from './svg/Logo_12weeks_en.svg';
import logoBravecto from './svg/Logo_Bravecto_en.svg';
import logoMerck from './svg/Logo_Merck_en.svg';

export default [
  logo12WeekUpTo,
  logo12Weeks,
  logoBravecto,
  logoMerck,
];
