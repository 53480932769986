export const SCREEN_HOME = 'home',
  SCREEN_GAME = 'game',
  ANIMAL_TYPE_DOG = 'dog',
  ANIMAL_TYPE_CAT = 'cat',
  SCREEN_STATE_BUILD = 'build',
  SCREEN_STATE_WAIT = 'wait',
  SCREEN_STATE_SELECT = 'select',
  SCREEN_STATE_GO_TO_GAME = 'go-to-game',
  SCREEN_STATE_DISPLAY_TIP = 'display-tip',
  SCREEN_STATE_TEARDOWN = 'teardown',
  SCREEN_STATE_RESET = 'reset',
  TIP_STATE_BUILD = 'build-tip',
  TIP_STATE_DISPLAY = SCREEN_STATE_DISPLAY_TIP,
  TIP_STATE_TEARDOWN = 'teardown-tip',
  END_GAME_STATE_BUILD = SCREEN_STATE_BUILD,
  END_GAME_STATE_DISPLAY = 'display',
  END_GAME_STATE_TEARDOWN = SCREEN_STATE_TEARDOWN;
