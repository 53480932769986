import React, { useContext } from 'react';
import { cloneDeep } from 'lodash';
import pStyles from '../../css_modules/ui_elements/Phone.module.css';
import ProfilesContext from '../../contexts/PhoneProfilesContext';
import ProfileCard from './ProfileCard';

function ProfileStack() {
  const { profiles, noInitialAnimation } = useContext(ProfilesContext),
    pClone = cloneDeep(profiles),
    profileCards = [];

  let firstProfile,
    lastProfile;

  if (noInitialAnimation) {
    firstProfile = pClone.shift();
    profileCards.push(firstProfile);
  } else {
    firstProfile = pClone.shift();
    lastProfile = pClone.pop();
    profileCards.push(firstProfile);
    profileCards.push(lastProfile);
  }

  return (
    <div className={pStyles.profileStack}>
      {
        profileCards.map(
          (profile, index, arr) => {
            if (index === arr.length - 1 && noInitialAnimation === false) {
              return (
                <ProfileCard
                  className={pStyles.profileCardSlideLeft}
                  profile={profile}
                  key={profile.key}
                />
              );
            }

            return <ProfileCard profile={profile} key={profile.key} />;
          }
        )
      }
    </div>
  );
}

export default ProfileStack;
