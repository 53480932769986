import { parseInt } from 'lodash';
import { isString } from 'lodash';

const determineScale = () => {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    return Math.min(
      windowWidth / parseInt(process.env.REACT_APP_BASE_WIDTH),
      windowHeight / parseInt(process.env.REACT_APP_BASE_HEIGHT)
    );
  },
  randomKey = (prefix) => {
    const key = Math.random().toString(36).substr(2, 9);
    let thePrefix = prefix;

    if (!isString(prefix) || prefix === '') {
      thePrefix = 'id';
    }
    return `${thePrefix}_${key}`;
  };

export {
  determineScale,
  randomKey,
};
