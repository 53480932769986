import catCat from './svg/tip_text_cat_cat_fr.svg';
import catFlea from './svg/tip_text_cat_flea_fr.svg';
import catStethoscope from './svg/tip_text_cat_stethoscope_fr.svg';
import catTick from './svg/tip_text_cat_tick_fr.svg';
import catTopical from './svg/tip_text_cat_topical_fr.svg';
import catTwelveWeek from './svg/tip_text_cat_twelveweek_fr.svg';
import dogChewable from './svg/tip_text_dog_chewable_fr.svg';
import dogFlea from './svg/tip_text_dog_flea_fr.svg';
import dogStethoscope from './svg/tip_text_dog_stethoscope_fr.svg';
import dogTemperature from './svg/tip_text_dog_temperature_fr.svg';
import dogTick from './svg/tip_text_dog_tick_fr.svg';
import dogTwelveWeek from './svg/tip_text_dog_twelveweek_fr.svg';

export default {
  cat: {
    cat: catCat,
    flea: catFlea,
    stethoscope: catStethoscope,
    tick: catTick,
    topical: catTopical,
    twelveweek: catTwelveWeek,
  },
  dog: {
    chewable: dogChewable,
    flea: dogFlea,
    stethoscope: dogStethoscope,
    temperature: dogTemperature,
    tick: dogTick,
    twelveweek: dogTwelveWeek,
  },
};
