import React from 'react';
import cx from 'classnames';
import FeatureProductCircle from './FeatureProductCircle';
import catProduct from '../../../images/png/Product_Cats.png';
import fpStyles from '../../../css_modules/ui_elements/home/FeatureProductCircle.module.css';

function CatFeatureProduct() {
  const classNamesArray = [fpStyles.product, fpStyles.cat];
  return (
    <FeatureProductCircle>
      <img src={catProduct} alt="Bravecto for Cats" className={cx(classNamesArray)} />
    </FeatureProductCircle>
  );
}

export default CatFeatureProduct;
