import React from 'react';
import BackgroundStack from './BackgroundStack';
import ProfileStack from './ProfileStack';
import pStyles from '../../css_modules/ui_elements/Phone.module.css';

function Profiles() {
  return (
    <div className={pStyles.profiles}>
      <BackgroundStack />
      <ProfileStack />
    </div>
  );
}

export default Profiles;
