import React, { useContext } from 'react';
import { isNull } from 'lodash';
import IntroText from './IntroText';
import PlayText from './PlayText';
import cStyles from '../../../css_modules/ui_elements/home/Content.module.css';
import ScreenContext from '../../../contexts/ScreenContext';
import {
  SCREEN_STATE_BUILD,
  SCREEN_STATE_GO_TO_GAME,
  SCREEN_STATE_SELECT,
  SCREEN_STATE_TEARDOWN,
  SCREEN_STATE_WAIT
} from '../../../constants/constants';

function Content() {
  const { screenState } = useContext(ScreenContext);

  let introText = null,
    playText = null;

  if (screenState === SCREEN_STATE_BUILD) {
    introText = <IntroText />;
  } else if (screenState === SCREEN_STATE_WAIT) {
    introText = <IntroText />;
  } else if (screenState === SCREEN_STATE_SELECT) {
    introText = <IntroText />;
    playText = <PlayText />;
  } else if (screenState === SCREEN_STATE_TEARDOWN) {
    introText = <IntroText />;
    playText = <PlayText />;
  } else if (screenState === SCREEN_STATE_GO_TO_GAME) {
    playText = <PlayText />;
  }

  if (isNull(introText) && isNull(playText)) {
    return (<>{null}</>);
  }

  return (
    <div className={cStyles.contentArea}>
      {introText}
      {playText}
    </div>
  );
}

export default Content;
