import React, { useContext } from 'react';
import { isNull, get } from 'lodash';
import PhoneButton from './PhoneButton';
import buttonIcon from '../../images/svg/Phone_Next_Button.svg';
import ButtonActionContext from '../../contexts/PhoneButtonActionContext';

function NextButton() {
  const actionContext = useContext(ButtonActionContext),
    clickHandlers = get(actionContext, 'clickHandlers'),
    labelText = get(actionContext, 'config.common.next'),
    noHandlers = isNull(clickHandlers);

  if (noHandlers) {
    return <></>;
  }

  return (
    <PhoneButton
      id="homePhoneNextButton"
      buttonClass="next"
      labelText={labelText}
      icon={buttonIcon}
    />
  );
}

export default NextButton;
