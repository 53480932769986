import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MatchGameContext from '../../../contexts/MatchGameContext';
import mgStyles from '../../../css_modules/ui_elements/game/MatchGame.module.css';
import cardBg from '../../../images/svg/Card_Back.svg';

class Card extends React.Component {
  constructor(props) {
    const { type } = props;

    super(props);

    this.state = {
      isFaceUp: false,
      type,
    };

    this.flipCard = this.flipCard.bind(this);
  }

  getType() {
    const { type } = this.state;

    return type;
  }

  flipCard() {
    const { isFaceUp } = this.state,
      {
        flipCard, hasGameStarted, startGame, gamePaused,
      } = this.context;

    if (!gamePaused) {
      if (isFaceUp === false) {
        if (!hasGameStarted()) {
          startGame();
        }

        setTimeout(
          () => {
            flipCard(this);
          },
          800
        );
      }

      this.setState({ isFaceUp: !isFaceUp });
    }
  }

  render() {
    const { asset } = this.props,
      { isFaceUp } = this.state,
      { gamePaused } = this.context,
      cardClasses = [mgStyles.card];

    let clickHandler = this.flipCard;

    if (isFaceUp) {
      cardClasses.push(mgStyles.faceUp);
      cardClasses.push(mgStyles.disabled);
      clickHandler = () => {};
    }

    if (gamePaused && !isFaceUp) {
      cardClasses.push(mgStyles.disabled);
    }

    const cardClassNames = cx(cardClasses);

    return (
      <button
        className={cardClassNames}
        onClick={clickHandler}
        type="button"
        aria-pressed={isFaceUp}
      >
        <div className={mgStyles.cardInner}>
          <div className={mgStyles.cardFront}>
            <img src={cardBg} alt="" />
          </div>
          <div className={mgStyles.cardBack}>
            <img src={asset} alt="" />
          </div>
        </div>
      </button>
    );
  }
}

Card.contextType = MatchGameContext;

Card.propTypes = {
  asset: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Card;
