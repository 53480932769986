import React from 'react';
import FeatureProductCircle from './FeatureProductCircle';
import dogProduct from '../../../images/png/Products_Dogs.png';
import fpStyles from '../../../css_modules/ui_elements/home/FeatureProductCircle.module.css';

function DogFeatureProduct() {
  return (
    <FeatureProductCircle>
      <img src={dogProduct} alt="Bravecto for Dogs" className={fpStyles.product} />
    </FeatureProductCircle>
  );
}

export default DogFeatureProduct;
