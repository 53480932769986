import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isNull } from 'lodash';
import { randomKey } from '../../utilities/functions';
import fpStyles from '../../css_modules/ui_elements/FinePrint.module.css';
import ScreenContext from '../../contexts/ScreenContext';
import { SCREEN_GAME, SCREEN_STATE_BUILD } from '../../constants/constants';

function FinePrint(props) {
  const { disclaimers } = props,
    keyedDisclaimers = disclaimers.map(
      (disclaimer) => (
        {
          copy: disclaimer,
          key: randomKey('disclaimer'),
        }
      )
    ),
    { selectedProfile, currentScreen, screenState } = useContext(ScreenContext),
    classNames = [fpStyles.finePrint],
    disclaimerClassNames = cx(fpStyles.small, 'm-0');

  if (!isNull(selectedProfile) && currentScreen === SCREEN_GAME) {
    const { animalType } = selectedProfile;
    if (animalType === 'cat') {
      classNames.push(fpStyles.game);
    }
    if (screenState === SCREEN_STATE_BUILD) {
      classNames.push(fpStyles.inAnimation);
    }
  }

  const componentClassNames = cx(classNames);

  return (
    <div id="homeScreenFinePrint" className={componentClassNames}>
      {
        keyedDisclaimers.map(
          (disclaimer) => (
            <p
              className={disclaimerClassNames}
              key={disclaimer.key}
              dangerouslySetInnerHTML={{ __html: disclaimer.copy }}
            />
          )
        )
      }
    </div>
  );
}

FinePrint.defaultProps = {
  disclaimers: [],
};

FinePrint.propTypes = {
  disclaimers: PropTypes.arrayOf(PropTypes.string),
};

export default FinePrint;
