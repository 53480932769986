import dogTemperature from './png/tip_dog_temperature_en.png';
import dogTick from './png/tip_dog_tick_en.png';
import dogStethoscope from './png/tip_dog_stethoscope_en.png';
import catStethoscope from './png/tip_cat_stethoscope_en.png';

export default {
  cat: {
    stethoscope: catStethoscope,
  },
  dog: {
    temperature: dogTemperature,
    tick: dogTick,
    stethoscope: dogStethoscope,
  },
};
