import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import pStyles from '../../css_modules/ui_elements/Phone.module.css';

function ProfileCard(props) {
  const { profile, className } = props,
    classes = [pStyles.profileCard];

  if (className !== '') {
    classes.push(className);
  }

  // 322 x 487.5031578947369
  // border Radius 4.842105263157895 4.842105263157895 13.31578947368421
  return (
    <div id={`profile_${profile.key}`} className={cx(classes)}>
      <img src={profile.image} alt={`${profile.name}: ${profile.description}`} />
      <div>
        <h2>{`${profile.name}, ${profile.age}`}</h2>
        <h4><pre>{profile.description}</pre></h4>
      </div>
    </div>
  );
}

ProfileCard.defaultProps = {
  className: '',
};

ProfileCard.propTypes = {
  profile: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
};

export default ProfileCard;
