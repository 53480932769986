import React from 'react';
import cx from 'classnames';
import { has, isNull, cloneDeep } from 'lodash';
import BackgroundSwoosh from '../ui_elements/BackgroundSwoosh';
import BravectoLogo from '../ui_elements/BravectoLogo';
import Phone from '../ui_elements/Phone';
import FinePrint from '../ui_elements/FinePrint';
import StopWatch from '../ui_elements/game/StopWatch';
import MatchGame from '../ui_elements/game/MatchGame';
import AppContext from '../../contexts/AppContext';
import { ScreenContextProvider } from '../../contexts/ScreenContext';
import screenStyles from '../../css_modules/screens/Screen.module.css';
import {
  SCREEN_STATE_BUILD,
  SCREEN_STATE_TEARDOWN,
  SCREEN_STATE_WAIT,
  SCREEN_STATE_RESET,
  SCREEN_STATE_DISPLAY_TIP,
} from '../../constants/constants';

class Game extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenState: SCREEN_STATE_BUILD,
      gameHasEnded: false,
    };

    this.setBuildTimeout = this.setBuildTimeout.bind(this);
    this.setTeardownTimeout = this.setTeardownTimeout.bind(this);
    this.clearBuildTimeout = this.clearBuildTimeout.bind(this);
    this.clearTeardownTimeout = this.clearTeardownTimeout.bind(this);
    this.updateScreenStateAfterTimeout = this.updateScreenStateAfterTimeout.bind(this);
    this.resetGame = this.resetGame.bind(this);
    this.showTip = this.showTip.bind(this);
    this.teardown = this.teardown.bind(this);
    this.endGame = this.endGame.bind(this);

    this.buildTimeout = null;
    this.teardownTimeout = null;
  }

  componentDidMount() {
    this.setBuildTimeout();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { screenState } = this.state,
      { screenState: prevScreenState } = prevState;

    if (screenState !== prevScreenState) {
      if (screenState === SCREEN_STATE_BUILD) {
        this.setBuildTimeout();
      } else if (screenState === SCREEN_STATE_TEARDOWN) {
        this.setTeardownTimeout();
      }
    }
  }

  componentWillUnmount() {
    if (!isNull(this.buildTimeout)) {
      clearTimeout(this.buildTimeout);
    }

    if (!isNull(this.teardownTimeout)) {
      clearTimeout(this.teardownTimeout);
    }
  }

  setBuildTimeout() {
    this.setScreenStateTimeout('buildTimeout', 'buildTime', SCREEN_STATE_WAIT);
  }

  setTeardownTimeout() {
    this.setScreenStateTimeout('teardownTimeout', 'resetTime', SCREEN_STATE_RESET, this.resetGame);
  }

  setScreenStateTimeout(
    timeoutName, timeoutDelay, timeoutScreenState, setStateCallback = () => {}
  ) {
    if (isNull(this[timeoutName])) {
      const { config: { game } } = this.context;

      if (has(game, timeoutDelay)) {
        const delay = game[timeoutDelay];

        this[timeoutName] = setTimeout(
          this.updateScreenStateAfterTimeout,
          delay,
          timeoutName,
          timeoutScreenState,
          setStateCallback
        );
      }
    }
  }

  setScreenState(screenState, afterTimeout = false, timeoutName = '', callback = () => {}) {
    const { screenState: currentScreenState } = this.state;

    if (screenState !== currentScreenState) {
      if (afterTimeout && timeoutName !== '') {
        this.clearTimeout(timeoutName);
      }

      this.setState(
        { screenState },
        callback
      );
    }
  }

  updateScreenStateAfterTimeout(timeoutName, timeoutScreenState, setStateCallback = () => {}) {
    this.setScreenState(timeoutScreenState, true, timeoutName, setStateCallback);
  }

  clearTimeout(timeoutName) {
    if (has(this, timeoutName)) {
      if (!isNull(this[timeoutName])) {
        clearTimeout(this[timeoutName]);
        this[timeoutName] = null;
      }
    }
  }

  clearBuildTimeout() {
    this.clearTimeout('buildTimeout');
  }

  clearTeardownTimeout() {
    this.clearTimeout('teardownTimeout');
  }

  teardown() {
    this.setScreenState(SCREEN_STATE_TEARDOWN);
  }

  resetGame() {
    const { resetGame } = this.context;
    resetGame();
    this.setScreenState(SCREEN_STATE_BUILD);
  }

  showTip() {
    this.setScreenState(SCREEN_STATE_DISPLAY_TIP);
  }

  endGame() {
    this.setState({ gameHasEnded: true });
  }

  render() {
    const classesArray = ['p-0', 'm-0', screenStyles.gameScreen, 'game-screen'],
      {
        config,
        selectedProfile,
        currentScreen,
        registerStopWatchHandlers,
        startTimer,
        stopTimer,
        getTime,
        locale,
      } = this.context,
      { screenState, gameHasEnded } = this.state,
      { animalType } = selectedProfile,
      { game: { disclaimers: { [animalType]: disclaimers } } } = config,
      propDisclaimers = cloneDeep(disclaimers);

    if (gameHasEnded) {
      const { game: { disclaimers: { end: endDisclaimers } } } = config;

      endDisclaimers.forEach(
        (disclaimer) => {
          propDisclaimers.push(disclaimer);
        }
      );
    }

    if (screenState === SCREEN_STATE_TEARDOWN) {
      classesArray.push(screenStyles.fadeOut);
    }

    const classes = cx(classesArray),
      gameContext = {
        screenState,
        selectedProfile,
        config,
        locale,
        currentScreen,
        registerStopWatchHandlers,
        stopwatch: {
          startTimer,
          stopTimer,
          getTime,
        },
        showTip: this.showTip,
        teardown: this.teardown,
        endGame: this.endGame,
      };

    return (
      <ScreenContextProvider value={gameContext}>
        <div className={classes}>
          <MatchGame />
          <BravectoLogo />
          <BackgroundSwoosh />
          <FinePrint disclaimers={propDisclaimers} />
          <Phone profiles={[selectedProfile]} />
          <StopWatch />
          <button type="button" onClick={this.teardown} className={screenStyles.homeButton}>Home</button>
        </div>
      </ScreenContextProvider>
    );
  }
}

Game.contextType = AppContext;

export default Game;
