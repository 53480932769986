import React, { useContext } from 'react';
import ScreenContext from '../../../contexts/ScreenContext';
import cStyles from '../../../css_modules/ui_elements/home/Content.module.css';
import { SCREEN_STATE_GO_TO_GAME } from '../../../constants/constants';

function PlayButton() {
  const {
    screenState,
    goToGameScreen,
    config: { home: { copy: { postSelection: { button } } } },
  } = useContext(ScreenContext);

  let clickHandler = goToGameScreen;

  if (screenState === SCREEN_STATE_GO_TO_GAME) {
    clickHandler = () => {};
  }

  return (
    <button type="button" className={cStyles.playButton} onClick={clickHandler}>{button}</button>
  );
}

export default PlayButton;
