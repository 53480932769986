import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isNull, get } from 'lodash';
import BravectoLogo from '../BravectoLogo';
import MatchGameContext from '../../../contexts/MatchGameContext';
import { ANIMAL_TYPE_CAT, ANIMAL_TYPE_DOG } from '../../../constants/constants';
import tpStyles from '../../../css_modules/ui_elements/game/TipPopup.module.css';
import TipContext from '../../../contexts/TipContext';

function TipContent(props) {
  const [assetPath, setAssetPath] = useState(''),
    [textPath, setTextPath] = useState(''),
    [assetIsSet, setAssetIsSet] = useState(false),
    [textIsSet, setTextIsSet] = useState(false),
    [isFlipped, setIsFlipped] = useState(false),
    [flipTimeout, setFlipTimeout] = useState(null),
    { type: typeProp, animal } = props,
    matchGameContext = useContext(MatchGameContext),
    locale = get(matchGameContext, 'locale'),
    type = typeProp.toLowerCase().replace(`_${locale}`, ''),
    typeStyle = type === ANIMAL_TYPE_CAT ? 'catcard' : type,
    {
      config: {
        tips: {
          flipTime,
          [animal]: {
            [type]: {
              disclaimer,
            },
          },
        },
      },
      tipImages,
      tipTexts,
    } = matchGameContext,
    { doFlip, flipped } = useContext(TipContext),
    componentClassNamesArray = [tpStyles.tipContent, animal],
    bgClassNamesArray = [tpStyles.tipBg, tpStyles[typeStyle], tpStyles[animal]],
    textClassNamesArray = [tpStyles.tipText, tpStyles[typeStyle], tpStyles[animal]];

  let disclaimerSvg;

  useEffect(
    () => {
      if (assetPath === '' && assetIsSet === false) {
        const { src } = tipImages[type];
        setAssetPath(src);
        setAssetIsSet(true);
      }

      if (textPath === '' && textIsSet === false) {
        const { src: textSrc } = tipTexts[type];
        setTextPath(textSrc);
        setTextIsSet(true);
      }

      return () => {
        if (!isNull(flipTimeout)) {
          clearTimeout(flipTimeout);
        }
      };
    },
    [assetPath, textPath, assetIsSet, textIsSet, flipTimeout, tipImages, type, tipTexts]
  );

  if (disclaimer !== '') {
    const disclaimerStyles = cx([tpStyles.disclaimer, tpStyles[typeStyle], tpStyles[animal]]);
    disclaimerSvg = (
      <svg className={disclaimerStyles}>
        <defs>
          <path id="textPath" d="M 0 283 A 283 283 0 0 0 566 283" />
        </defs>
        <text>
          <textPath href="#textPath" className={tpStyles.textPath}>
            {disclaimer}
          </textPath>
        </text>
      </svg>
    );
  }

  const componentClassNames = cx(componentClassNamesArray),
    bgClassNames = cx(bgClassNamesArray),
    textClassNames = cx(textClassNamesArray);

  if (assetPath !== '' && assetIsSet === true && textPath !== '' && textIsSet === true && isFlipped === false) {
    setIsFlipped(true);
    if (!isNull(flipTimeout)) {
      clearTimeout(flipTimeout);
    }
    setFlipTimeout(setTimeout(doFlip, flipTime));
  }

  if (isFlipped && flipped) {
    if (!isNull(flipTimeout)) {
      clearTimeout(flipTimeout);
      setFlipTimeout(null);
    }
  }

  return (
    <div className={componentClassNames}>
      <BravectoLogo inPopup />
      <img src={assetPath} alt="" className={bgClassNames} />
      <img src={textPath} alt="" className={textClassNames} />
      {disclaimerSvg}
    </div>
  );
}

TipContent.propTypes = {
  type: PropTypes.string.isRequired,
  animal: PropTypes.oneOf([ANIMAL_TYPE_DOG, ANIMAL_TYPE_CAT]).isRequired,
};

export default TipContent;
