import React, { useContext } from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';
import heart from '../../../images/svg/Product_Heart_shadow.svg';
import heartNoShadow from '../../../images/svg/Product_Heart_no_shadow.svg';
import hStyles from '../../../css_modules/ui_elements/home/ProductHeart.module.css';
import ScreenContext from '../../../contexts/ScreenContext';
import { SCREEN_STATE_GO_TO_GAME, SCREEN_STATE_SELECT } from '../../../constants/constants';

function ProductHeart() {
  const componentClassArray = [hStyles.productHeart],
    { screenState, selectedProfile } = useContext(ScreenContext),
    isEdge = /Edg(e)?/.test(window.navigator.userAgent);

  if (!isNull(selectedProfile) && screenState === SCREEN_STATE_SELECT) {
    componentClassArray.push(hStyles.inAnimation);
  } else if (!isNull(selectedProfile) && screenState === SCREEN_STATE_GO_TO_GAME) {
    componentClassArray.push(hStyles.outAnimation);
  }

  const classes = cx(componentClassArray);

  let heartSvg = heart;

  if (isEdge) {
    heartSvg = heartNoShadow;
  }

  return (
    <img alt="" src={heartSvg} className={classes} />
  );
}

export default ProductHeart;
