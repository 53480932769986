import React from 'react';
import cx from 'classnames';
import {
  cloneDeep, isNull, assign, forOwn, set, toNumber, has
} from 'lodash';
import axios from 'axios';
import { randomKey } from '../../../utilities/functions';
import Card from './Card';
import TipPopup from './TipPopup';
import EndGamePopup from './EndGamePopup';
import ScreenContext from '../../../contexts/ScreenContext';
import { MatchGameContextProvider } from '../../../contexts/MatchGameContext';
import mgStyles from '../../../css_modules/ui_elements/game/MatchGame.module.css';
import { SCREEN_STATE_BUILD } from '../../../constants/constants';
import tipAssets from '../../../images/tipAssets';
import tipAssetsEn from '../../../images/tipAssetsEn';
import tipAssetsFr from '../../../images/tipAssetsFr';
import tipTextEn from '../../../images/tipTextEn';
import tipTextFr from '../../../images/tipTextFr';

class MatchGame extends React.Component {
  constructor(props) {
    super(props);

    this.updateGameContext = this.updateGameContext.bind(this);
    this.flipCard = this.flipCard.bind(this);
    this.hasGameStarted = this.hasGameStarted.bind(this);
    this.startGame = this.startGame.bind(this);
    this.pauseGame = this.pauseGame.bind(this);
    this.unpauseGame = this.unpauseGame.bind(this);
    this.handleReturnedPlayId = this.handleReturnedPlayId.bind(this);
    this.handleFinishResponse = this.handleFinishResponse.bind(this);

    this.state = {
      cards: [],
      cardsShuffled: false,
      cardsReady: false,
      playId: null,
      gameContext: {
        gameStarted: false,
        gamePaused: false,
        gameOver: false,
        openedCards: [],
        tipType: '',
        animalType: '',
        stopwatch: null,
        matchedCards: 0,
        config: null,
        tipImages: null,
        tipTexts: null,
        update: this.updateGameContext,
        hasGameStarted: this.hasGameStarted,
        startGame: this.startGame,
        unpauseGame: this.unpauseGame,
        flipCard: this.flipCard,
        locale: '',
      },
    };
  }

  componentDidMount() {
    const {
      config: { game }, selectedProfile: { animalType }, showTip, teardown, locale,
    } = this.context;

    this.generateCards();
    this.preloadImages(animalType);
    this.updateGameContext({
      config: game, showTip, resetGame: teardown, locale,
    });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
        cards, cardsShuffled, cardsReady, gameContext,
      } = this.state,
      { cardsReady: prevCardsReady, gameContext: prevContext } = prevState,
      { stopwatch, selectedProfile: { animalType } } = this.context,
      {
        stopwatch: contextStopwatch, gameStarted, openedCards, gamePaused,
      } = gameContext,
      {
        openedCards: prevOpenedCards, gamePaused: prevGamePaused,
      } = prevContext;

    if (cards.length === 12 && cardsReady === false && cardsShuffled) {
      this.cardsAreReady();
    }

    if (prevCardsReady === false && cardsReady === true) {
      if (isNull(contextStopwatch)) {
        this.updateGameContext(
          {
            stopwatch: {
              start: stopwatch.startTimer,
              stop: stopwatch.stopTimer,
              getTime: stopwatch.getTime,
            },
            animalType,
          }
        );
      }
    }

    if (cardsReady && gameStarted && openedCards.length !== prevOpenedCards.length) {
      this.checkForMatches();
    }

    if (prevGamePaused === true && gamePaused === false) {
      this.updateGameContext({ tipType: '' });
    }
  }

  cardsAreReady() {
    this.setState({ cardsReady: true });
  }

  generateCards() {
    const { config: { game: { deck } }, selectedProfile } = this.context,
      { animalType } = selectedProfile,
      { standard, [animalType]: aType } = deck,
      cardSet = cloneDeep(standard);

    aType.forEach(
      (type) => {
        cardSet.push(type);
      }
    );

    cardSet.forEach(
      (cardType) => {
        import(`../../../images/svg/Card_${cardType}.svg`)
          .then(
            (svgAsset) => {
              const { cards: cardsFromState } = this.state,
                cards = cloneDeep(cardsFromState),
                { default: assetPath } = svgAsset;

              cards.push(
                <Card asset={assetPath} type={cardType} key={randomKey('card')} />
              );
              cards.push(
                <Card asset={assetPath} type={cardType} key={randomKey('card')} />
              );

              this.setState({ cards }, this.shuffleCards);
            }
          );
      }
    );
  }

  preloadImages(animalType) {
    const importedAnimalImages = tipAssets[animalType],
      importedAnimalImagesEn = tipAssetsEn[animalType],
      importedAnimalImagesFr = tipAssetsFr[animalType],
      animalAssets = {},
      importedAnimalTextsEn = tipTextEn[animalType],
      importedAnimalTextsFr = tipTextFr[animalType],
      animalTexts = {},
      { locale } = this.context;

    forOwn(
      importedAnimalImages,
      (value, key) => {
        const imgLoader = new Image();
        imgLoader.src = value;
        set(animalAssets, key, imgLoader);
      }
    );

    if (locale === 'fr') {
      forOwn(
        importedAnimalImagesFr,
        (value, key) => {
          const imgLoader = new Image();
          imgLoader.src = value;
          set(animalAssets, key, imgLoader);
        }
      );

      forOwn(
        importedAnimalTextsFr,
        (value, key) => {
          const imgLoader = new Image();
          imgLoader.src = value;
          set(animalTexts, key, imgLoader);
        }
      );
    } else {
      forOwn(
        importedAnimalImagesEn,
        (value, key) => {
          const imgLoader = new Image();
          imgLoader.src = value;
          set(animalAssets, key, imgLoader);
        }
      );

      forOwn(
        importedAnimalTextsEn,
        (value, key) => {
          const imgLoader = new Image();
          imgLoader.src = value;
          set(animalTexts, key, imgLoader);
        }
      );
    }

    this.updateGameContext({ tipImages: animalAssets, tipTexts: animalTexts });
  }

  shuffleCards() {
    const newState = cloneDeep(this.state),
      { cards } = newState;

    let { cardsShuffled } = newState;

    if (cards.length === 12 && cardsShuffled === false) {
      let currentIndex = cards.length,
        temporaryValue,
        randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = cards[currentIndex];
        cards[currentIndex] = cards[randomIndex];
        cards[randomIndex] = temporaryValue;
      }

      cardsShuffled = true;

      this.setState({ cards, cardsShuffled });
    }
  }

  updateGameContext(newContext) {
    this.setState(
      (state) => {
        const { gameContext: prevContext } = state,
          updatedContext = assign({}, prevContext, newContext);

        return { gameContext: updatedContext };
      }
    );
  }

  flipCard(card) {
    const { gameContext: { openedCards } } = this.state,
      newOpenedCards = assign([], openedCards);

    newOpenedCards.push(card);

    this.updateGameContext({ openedCards: newOpenedCards });
  }

  checkForMatches() {
    const { gameContext } = this.state,
      { openedCards } = gameContext,
      openCardCount = openedCards.length;

    if (openCardCount === 2) {
      if (openedCards[0].getType() === openedCards[1].getType()) {
        // we have a match
        this.doMatch();
      } else {
        // we don't hae a match
        this.doNoMatch();
      }
    }
  }

  doMatch() {
    const { gameContext: { openedCards, matchedCards } } = this.state,
      tipType = openedCards[0].getType();

    this.pauseGame();

    this.updateGameContext(
      {
        tipType,
        openedCards: [],
        matchedCards: (matchedCards + 1),
      }
    );
  }

  doNoMatch() {
    const { gameContext } = this.state,
      { openedCards } = gameContext;

    openedCards.forEach(
      (card) => {
        card.flipCard();
      }
    );

    this.updateGameContext({ openedCards: [] });
  }

  isGameOver() {
    const { gameContext: { matchedCards } } = this.state,
      { config: { common: { matchesToWin } } } = this.context;

    return matchedCards === matchesToWin && this.hasGameStarted();
  }


  startGame() {
    const { gameContext: { stopwatch } } = this.state,
      { config: { common: { communicateWithBackend }, game: { startUrl } } } = this.context;

    stopwatch.start();

    if (communicateWithBackend) {
      axios.get(startUrl)
        .then(this.handleReturnedPlayId)
        .catch((error) => { console.log(error); });
    }

    this.updateGameContext({ gameStarted: true });
  }

  stopGame() {
    const { gameContext: { stopwatch }, playId } = this.state,
      { endGame, config: { common: { communicateWithBackend }, game: { endUrl } } } = this.context;

    stopwatch.stop();
    endGame();

    if (communicateWithBackend && !isNull(playId)) {
      const playTime = stopwatch.getTime().split(':');
      axios.post(
        `${endUrl}${playId}`,
        JSON.stringify(
          {
            minutesPlayed: toNumber(playTime[0]),
            secondsPlayed: toNumber(playTime[1]),
          }
        ),
        {
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            withCredentials: true,
          },
        }
      )
        .then(this.handleFinishResponse)
        .catch((error) => { console.log(error); });
    }

    this.updateGameContext({ gameStarted: false, gameOver: true });
  }

  pauseGame() {
    const { gameContext: { stopwatch } } = this.state;

    stopwatch.stop();
    this.updateGameContext({ gamePaused: true });
  }

  unpauseGame() {
    const { gameContext: { stopwatch } } = this.state,
      isGameOver = this.isGameOver();

    if (isGameOver) {
      this.stopGame();
    } else {
      stopwatch.start();
      this.updateGameContext({ gamePaused: false });
    }
  }

  hasGameStarted() {
    const { gameContext: { gameStarted } } = this.state;
    return gameStarted;
  }

  handleReturnedPlayId(response) {
    const { status, data } = response;

    if (status === 201 && has(data, 'id')) {
      const { id: playId } = data;

      this.setState({ playId });
    }
  }

  handleFinishResponse(response) {
    const { status } = response;

    if (status === 204 || status === 208) {
      this.setState({ playId: null });
    }
  }

  render() {
    const { cards, gameContext, cardsReady } = this.state,
      { tipType, gameOver: isGameOver } = gameContext,
      { screenState } = this.context,
      classNamesArray = [mgStyles.matchGame];

    let popup;

    if (screenState === SCREEN_STATE_BUILD && cardsReady) {
      classNamesArray.push(mgStyles.inAnimation);
    } else if (screenState === SCREEN_STATE_BUILD && !cardsReady) {
      classNamesArray.push(mgStyles.waitUntilReady);
    }

    if (tipType !== '') {
      popup = <TipPopup />;
    }

    if (isGameOver) {
      popup = <EndGamePopup />;
    }

    const classNames = cx(classNamesArray);

    return (
      <MatchGameContextProvider value={gameContext}>
        <div className={classNames}>
          {cards}
          {popup}
        </div>
      </MatchGameContextProvider>
    );
  }
}

MatchGame.contextType = ScreenContext;

export default MatchGame;
