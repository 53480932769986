import React, { useContext } from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';
import ScreenContext from '../../../contexts/ScreenContext';
import PlayButton from './PlayButton';
import cStyles from '../../../css_modules/ui_elements/home/Content.module.css';
import {
  SCREEN_STATE_GO_TO_GAME,
  SCREEN_STATE_SELECT,
  SCREEN_STATE_TEARDOWN
} from '../../../constants/constants';

function PlayText() {
  const {
      screenState,
      selectedProfile,
      config: { home: { copy: { postSelection: { heading } } } },
    } = useContext(ScreenContext),
    classes = [cStyles.playText];

  if (!isNull(selectedProfile) && screenState === SCREEN_STATE_SELECT) {
    classes.push(cStyles.inAnimation);
  } else if (isNull(selectedProfile) && screenState === SCREEN_STATE_TEARDOWN) {
    classes.push('d-none');
  } else if (!isNull(selectedProfile) && screenState === SCREEN_STATE_GO_TO_GAME) {
    classes.push(cStyles.outAnimation);
  }

  return (
    <div className={cx(classes)}>
      <h1>{heading}</h1>
      <PlayButton />
    </div>
  );
}

export default PlayText;
