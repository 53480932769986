import React, { Children, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isNull } from 'lodash';
import GradientCircleBackground from '../GradientCircleBackground';
import fpStyles from '../../../css_modules/ui_elements/home/FeatureProductCircle.module.css';
import ScreenContext from '../../../contexts/ScreenContext';
import { SCREEN_STATE_GO_TO_GAME, SCREEN_STATE_SELECT } from '../../../constants/constants';

function FeatureProductCircle(props) {
  const { children: kids } = props,
    componentClassArray = [fpStyles.featureProductCircle],
    { screenState, selectedProfile } = useContext(ScreenContext);

  if (!isNull(selectedProfile) && screenState === SCREEN_STATE_SELECT) {
    componentClassArray.push(fpStyles.inAnimation);
  } else if (!isNull(selectedProfile) && screenState === SCREEN_STATE_GO_TO_GAME) {
    componentClassArray.push(fpStyles.outAnimation);
  }

  const classes = cx(componentClassArray);

  return (
    <div className={classes}>
      <GradientCircleBackground className={fpStyles.gradientCircle} />
      {
        Children.map(
          kids,
          (child) => {
            const { type } = child;
            if (type === 'img') {
              return child;
            }

            return null;
          }
        )
      }
    </div>
  );
}

FeatureProductCircle.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.arrayOf(PropTypes.node), PropTypes.node]
  ).isRequired,
};

export default FeatureProductCircle;
