import React from 'react';
import PropTypes from 'prop-types';
import gradientCircle from '../../images/svg/Gradient_Circle.svg';
import productsCircle from '../../images/svg/Products_Circle.svg';

function GradientCircleBackground(props) {
  const { className } = props,
    isEdge = /Edg(e)?/.test(window.navigator.userAgent);

  let gCircle = gradientCircle;

  if (isEdge) {
    gCircle = productsCircle;
  }
  return (
    <img alt="" src={gCircle} className={className} />
  );
}

GradientCircleBackground.propTypes = {
  className: PropTypes.string.isRequired,
};

export default GradientCircleBackground;
