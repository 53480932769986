import React from 'react';
import {
  cloneDeep,
  isFunction,
  get,
  merge
} from 'lodash';

import Game from './components/screens/Game';
import Home from './components/screens/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './css_modules/App.module.css';
import { determineScale } from './utilities/functions';
import { AppContextProvider } from './contexts/AppContext';
import runtimeConfig from './utilities/runtime-config';
import { SCREEN_HOME, SCREEN_GAME } from './constants/constants';
import cardAssets from './images/cardAssets';
import cardAssetsEn from './images/cardAssetsEn';
import cardAssetsFr from './images/cardAssetsFr';
import otherAssets from './images/assetsToPreload';
import otherAssetsEn from './images/assetsToPreloadEn';
import otherAssetsFr from './images/assetsToPreloadFr';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.updateDimensions = this.updateDimensions.bind(this);
    this.selectProfile = this.selectProfile.bind(this);
    this.loadGameScreenComponent = this.loadGameScreenComponent.bind(this);
    this.resetGame = this.resetGame.bind(this);
    this.registerStopWatchHandlers = this.registerStopWatchHandlers.bind(this);

    const locale = get(document, 'documentElement.lang');

    this.state = {
      scale: 1,
      runtimeData: {
        config: get(runtimeConfig, locale),
        selectedProfile: null,
        currentScreen: SCREEN_HOME,
        startTimer: null,
        stopTimer: null,
        resetTimer: null,
        getTime: null,
        resetInProgress: false,
      },
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);

    const locale = get(document, 'documentElement.lang'),
      profiles = get(runtimeConfig, `${locale}.common.profiles`),
      assetPreload = (asset) => {
        const assetLoader = new Image();
        assetLoader.src = asset;
      };

    profiles.forEach(
      (profile) => {
        const imgLoader = new Image(),
          { image } = profile;
        imgLoader.src = image;
      }
    );

    cardAssets.forEach(assetPreload);

    if (locale === 'fr') {
      cardAssetsFr.forEach(assetPreload);
    } else {
      cardAssetsEn.forEach(assetPreload);
    }

    otherAssets.forEach(assetPreload);

    if (locale === 'fr') {
      otherAssetsFr.forEach(assetPreload);
    } else {
      otherAssetsEn.forEach(assetPreload);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  selectProfile(profile) {
    const newState = cloneDeep(this.state);

    newState.runtimeData.selectedProfile = profile;

    this.setState(newState);
  }

  loadGameScreenComponent() {
    const newState = cloneDeep(this.state);

    newState.runtimeData.currentScreen = SCREEN_GAME;

    this.setState(newState);
  }

  registerStopWatchHandlers(
    {
      startTimer, stopTimer, resetTimer, getTime,
    }
  ) {
    const newState = cloneDeep(this.state);

    newState.runtimeData.startTimer = startTimer;
    newState.runtimeData.stopTimer = stopTimer;
    newState.runtimeData.resetTimer = resetTimer;
    newState.runtimeData.getTime = getTime;

    this.setState(newState);
  }

  resetGame() {
    const newState = cloneDeep(this.state);

    if (isFunction(newState.runtimeData.resetTimer)) {
      newState.runtimeData.resetTimer();
    }

    newState.runtimeData.selectedProfile = null;

    newState.runtimeData.startTimer = null;
    newState.runtimeData.stopTimer = null;
    newState.runtimeData.resetTimer = null;
    newState.runtimeData.getTime = null;
    newState.runtimeData.currentScreen = SCREEN_HOME;
    newState.runtimeData.resetInProgress = true;

    this.setState(newState, this.resetDone);
  }

  resetDone() {
    const { runtimeData } = this.state,
      newRuntimeData = cloneDeep(runtimeData);

    newRuntimeData.resetInProgress = false;

    this.setState({ runtimeData: newRuntimeData });
  }

  updateDimensions() {
    this.setState({ scale: determineScale() });
  }

  render() {
    const classes = [styles.baseSize, 'p-0'].join(' '),
      { scale, runtimeData } = this.state,
      { currentScreen } = runtimeData,
      inlineStyle = {
        transform: `scale(${scale})`,
        transformOrigin: 'left top',
        backgroundColor: '#fff',
      },
      rtContext = {
        selectProfile: this.selectProfile,
        resetGame: this.resetGame,
        registerStopWatchHandlers: this.registerStopWatchHandlers,
        loadGameComponent: this.loadGameScreenComponent,
        locale: get(document, 'documentElement.lang'),
      },
      runtimeContext = merge({}, runtimeData, rtContext);

    let component;

    if (currentScreen === SCREEN_HOME) {
      component = <Home />;
    } else if (currentScreen === SCREEN_GAME) {
      component = <Game />;
    }

    return (
      <AppContextProvider value={runtimeContext}>
        <div className={classes} style={inlineStyle}>
          {component}
        </div>
      </AppContextProvider>
    );
  }
}

export default App;
