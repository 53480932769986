import React from 'react';
import cx from 'classnames';
import { isNull, has, isFunction } from 'lodash';
import BackgroundSwoosh from '../ui_elements/BackgroundSwoosh';
import BravectoLogo from '../ui_elements/BravectoLogo';
import MerckLogo from '../ui_elements/home/MerckLogo';
import Phone from '../ui_elements/Phone';
import Content from '../ui_elements/home/Content';
import DogFeatureProduct from '../ui_elements/home/DogFeatureProduct';
import CatFeatureProduct from '../ui_elements/home/CatFeatureProduct';
import screenStyles from '../../css_modules/screens/Screen.module.css';
import AppContext from '../../contexts/AppContext';
import { ScreenContextProvider } from '../../contexts/ScreenContext';
import ProductHeart from '../ui_elements/home/ProductHeart';
import {
  ANIMAL_TYPE_DOG, ANIMAL_TYPE_CAT, SCREEN_STATE_BUILD, SCREEN_STATE_WAIT, SCREEN_STATE_TEARDOWN,
  SCREEN_STATE_SELECT, SCREEN_STATE_RESET, SCREEN_STATE_GO_TO_GAME
} from '../../constants/constants';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenState: SCREEN_STATE_BUILD,
    };

    this.setBuildTimeout = this.setBuildTimeout.bind(this);
    this.setWaitTimeout = this.setWaitTimeout.bind(this);
    this.setSelectTimeout = this.setSelectTimeout.bind(this);
    this.setTeardownTimeout = this.setTeardownTimeout.bind(this);
    this.clearBuildTimeout = this.clearBuildTimeout.bind(this);
    this.clearWaitTimeout = this.clearWaitTimeout.bind(this);
    this.clearSelectTimeout = this.clearSelectTimeout.bind(this);
    this.clearTeardownTimeout = this.clearTeardownTimeout.bind(this);
    this.goToGameScreen = this.goToGameScreen.bind(this);
    this.tellAppToLoadGameComponent = this.tellAppToLoadGameComponent.bind(this);
    this.updateScreenStateAfterTimeout = this.updateScreenStateAfterTimeout.bind(this);
    this.resetGame = this.resetGame.bind(this);
    this.selectProfile = this.selectProfile.bind(this);

    this.buildTimeout = null;
    this.waitTimeout = null;
    this.selectTimeout = null;
    this.teardownTimeout = null;
    this.goToGameTimeout = null;
  }

  componentDidMount() {
    this.setBuildTimeout();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { screenState } = this.state,
      { screenState: prevScreenState } = prevState;

    if (screenState !== prevScreenState) {
      if (screenState === SCREEN_STATE_BUILD) {
        this.setBuildTimeout();
      } else if (screenState === SCREEN_STATE_WAIT) {
        this.setWaitTimeout();
      } else if (screenState === SCREEN_STATE_SELECT) {
        this.setSelectTimeout();
      } else if (screenState === SCREEN_STATE_TEARDOWN) {
        this.setTeardownTimeout();
      } else if (screenState === SCREEN_STATE_GO_TO_GAME) {
        this.setGoToGameTimeout();
      }
    }
  }

  componentWillUnmount() {
    if (!isNull(this.buildTimeout)) {
      clearTimeout(this.buildTimeout);
    }

    if (!isNull(this.waitTimeout)) {
      clearTimeout(this.waitTimeout);
    }

    if (!isNull(this.selectTimeout)) {
      clearTimeout(this.selectTimeout);
    }

    if (!isNull(this.teardownTimeout)) {
      clearTimeout(this.teardownTimeout);
    }

    if (!isNull(this.goToGameTimeout)) {
      clearTimeout(this.goToGameTimeout);
    }
  }

  setBuildTimeout() {
    this.setScreenStateTimeout('buildTimeout', 'buildTime', SCREEN_STATE_WAIT);
  }

  setWaitTimeout() {
    this.setScreenStateTimeout('waitTimeout', 'waitTime', SCREEN_STATE_TEARDOWN);
  }

  setSelectTimeout() {
    this.setScreenStateTimeout('selectTimeout', 'selectTime', SCREEN_STATE_TEARDOWN);
  }

  setTeardownTimeout() {
    this.setScreenStateTimeout('teardownTimeout', 'resetTime', SCREEN_STATE_RESET, this.resetGame);
  }

  setGoToGameTimeout() {
    const { config: { home: { toGameTime } } } = this.context;
    this.goToGameTimeout = setTimeout(
      this.tellAppToLoadGameComponent,
      toGameTime
    );
  }

  setScreenStateTimeout(
    timeoutName, timeoutDelay, timeoutScreenState, setStateCallback = () => {}
  ) {
    if (isNull(this[timeoutName])) {
      const { config: { home } } = this.context;

      if (has(home, timeoutDelay)) {
        const delay = home[timeoutDelay];

        this[timeoutName] = setTimeout(
          this.updateScreenStateAfterTimeout,
          delay,
          timeoutName,
          timeoutScreenState,
          setStateCallback
        );
      }
    }
  }

  setScreenState(screenState, afterTimeout = false, timeoutName = '', callback = () => {}) {
    const { screenState: currentScreenState } = this.state;

    if (screenState !== currentScreenState) {
      if (afterTimeout && timeoutName !== '') {
        this.clearTimeout(timeoutName);
      }

      this.setState(
        { screenState },
        callback
      );
    }
  }

  updateScreenStateAfterTimeout(timeoutName, timeoutScreenState, setStateCallback = () => {}) {
    this.setScreenState(timeoutScreenState, true, timeoutName, setStateCallback);
  }

  clearTimeout(timeoutName) {
    if (has(this, timeoutName)) {
      if (!isNull(this[timeoutName])) {
        clearTimeout(this[timeoutName]);
        this[timeoutName] = null;
      }
    }
  }

  clearBuildTimeout() {
    this.clearTimeout('buildTimeout');
  }

  clearWaitTimeout() {
    this.clearTimeout('waitTimeout');
  }

  clearSelectTimeout() {
    this.clearTimeout('selectTimeout');
  }

  clearTeardownTimeout() {
    this.clearTimeout('teardownTimeout');
  }

  clearGoToGameTimeout() {
    this.clearTimeout('goToGameTimeout');
  }

  goToGameScreen() {
    this.clearWaitTimeout();
    this.setScreenState(SCREEN_STATE_GO_TO_GAME);
  }

  tellAppToLoadGameComponent() {
    const { loadGameComponent } = this.context;
    this.clearGoToGameTimeout();

    if (isFunction(loadGameComponent)) {
      loadGameComponent();
    }
  }

  resetGame() {
    const { resetGame } = this.context;
    resetGame();
    this.setScreenState(SCREEN_STATE_BUILD);
  }

  selectProfile(profile) {
    const { selectProfile } = this.context;

    selectProfile(profile);
    this.setScreenState(SCREEN_STATE_SELECT);
  }

  render() {
    const classesArray = ['p-0', 'm-0', screenStyles.gameScreen, 'home-screen'],
      {
        config, selectedProfile, currentScreen, locale,
      } = this.context,
      { screenState } = this.state,
      { common: { profiles } } = config,
      catProduct = <CatFeatureProduct />,
      dogProduct = <DogFeatureProduct />;

    let product = null,
      productPlacement = null;

    if (!isNull(selectedProfile)) {
      const { animalType } = selectedProfile;

      if (animalType === ANIMAL_TYPE_DOG) {
        product = dogProduct;
      } else if (animalType === ANIMAL_TYPE_CAT) {
        product = catProduct;
      }

      if (!isNull(product)) {
        productPlacement = (
          <>
            {product}
            <ProductHeart />
          </>
        );
      }
    }

    if (screenState === SCREEN_STATE_TEARDOWN) {
      classesArray.push(screenStyles.fadeOut);
    }

    const classes = cx(classesArray),
      homeContext = {
        screenState,
        selectedProfile,
        config,
        currentScreen,
        locale,
        setBuildTimeout: this.setBuildTimeout,
        setWaitTimeout: this.setWaitTimeout,
        setSelectTimeout: this.setSelectTimeout,
        setTeardownTimeout: this.setTeardownTimeout,
        clearBuildTimeout: this.clearBuildTimeout,
        clearWaitTimeout: this.clearWaitTimeout,
        clearSelectTimeout: this.clearSelectTimeout,
        clearTeardownTimeout: this.clearTeardownTimeout,
        goToGameScreen: this.goToGameScreen,
        selectProfile: this.selectProfile,
      };

    return (
      <ScreenContextProvider value={homeContext}>
        <div className={classes}>
          <BackgroundSwoosh />
          <BravectoLogo />
          <MerckLogo />
          <Phone profiles={profiles} />
          <Content />
          {productPlacement}
        </div>
      </ScreenContextProvider>
    );
  }
}

Home.contextType = AppContext;

export default Home;
