import catCat from './png/tip_cat_cat.png';
import catFlea from './png/tip_cat_flea.png';
import catTick from './png/tip_cat_tick.png';
import catTopical from './png/tip_cat_topical.png';
import catTwelveWeek from './png/tip_cat_twelveweek.png';
import dogChewable from './png/tip_dog_chewable.png';
import dogFlea from './png/tip_dog_flea.png';
import dogTwelveWeek from './png/tip_dog_twelveweek.png';

export default {
  cat: {
    cat: catCat,
    flea: catFlea,
    tick: catTick,
    topical: catTopical,
    twelveweek: catTwelveWeek,
  },
  dog: {
    chewable: dogChewable,
    flea: dogFlea,
    twelveweek: dogTwelveWeek,
  },
};
