import React, { useContext } from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';
import ScreenContext from '../../../contexts/ScreenContext';
import cStyles from '../../../css_modules/ui_elements/home/Content.module.css';
import {
  SCREEN_STATE_BUILD,
  SCREEN_STATE_SELECT,
  SCREEN_STATE_TEARDOWN
} from '../../../constants/constants';

function IntroText() {
  const {
      screenState,
      selectedProfile,
      config: { home: { copy: { preSelection: { heading, challenge, cta } } } },
    } = useContext(ScreenContext),
    dangerousHtml = `${challenge}<br /><strong>${cta}</strong>`,
    classes = [cStyles.introText];

  if (isNull(selectedProfile) && screenState === SCREEN_STATE_BUILD) {
    classes.push(cStyles.inAnimation);
  } else if (!isNull(selectedProfile) && screenState === SCREEN_STATE_TEARDOWN) {
    classes.push('d-none');
  } else if (!isNull(selectedProfile) && screenState === SCREEN_STATE_SELECT) {
    classes.push(cStyles.outAnimation);
  }

  const classNames = cx(classes);

  return (
    <div className={classNames}>
      <h1>{heading}</h1>
      {/* eslint-disable-next-line react/no-danger */}
      <h3 dangerouslySetInnerHTML={{ __html: dangerousHtml }} />
    </div>
  );
}

export default IntroText;
