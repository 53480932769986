import React from 'react';
import cx from 'classnames';
import MatchGameContext from '../../../contexts/MatchGameContext';
import egStyles from '../../../css_modules/ui_elements/game/EndGamePopup.module.css';
import GradientCircleBackground from '../GradientCircleBackground';
import { END_GAME_STATE_BUILD, END_GAME_STATE_TEARDOWN } from '../../../constants/constants';
import { randomKey } from '../../../utilities/functions';

class EndGamePopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayState: END_GAME_STATE_BUILD,
    };
  }

  render() {
    const { displayState } = this.state,
      { stopwatch: { getTime }, resetGame, config: { endGame } } = this.context,
      { congrats, timing, playAgain } = endGame,
      addBrTags = (part, index, array) => {
        if (index === array.length - 1) {
          return (
            <React.Fragment key={randomKey()}>
              {part}
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={randomKey()}>
            {part}
            <br />
          </React.Fragment>
        );
      },
      removeEmptyStrings = (item) => item.length > 0,
      congratsParts = congrats.split('\n').filter(removeEmptyStrings),
      timingParts = timing.split('\n').filter(removeEmptyStrings),
      congratsMarkup = congratsParts.map(addBrTags),
      timingMarkup = timingParts.map(addBrTags),
      classNamesArray = [egStyles.egPopup];

    if (displayState === END_GAME_STATE_BUILD) {
      classNamesArray.push(egStyles.inAnimation);
    } else if (displayState === END_GAME_STATE_TEARDOWN) {
      classNamesArray.push(egStyles.outAnimation);
    }

    const classNames = cx(classNamesArray);

    return (
      <div className={classNames}>
        <GradientCircleBackground className={egStyles.gradientCircle} />
        <h1>{congratsMarkup}</h1>
        <h2>
          {timingMarkup}
          &nbsp;
          {getTime()}
        </h2>
        <button type="button" onClick={resetGame} className={egStyles.playAgain}>{playAgain}</button>
      </div>
    );
  }
}

EndGamePopup.contextType = MatchGameContext;

export default EndGamePopup;
