import catCat from './svg/tip_text_cat_cat_en.svg';
import catFlea from './svg/tip_text_cat_flea_en.svg';
import catStethoscope from './svg/tip_text_cat_stethoscope_en.svg';
import catTick from './svg/tip_text_cat_tick_en.svg';
import catTopical from './svg/tip_text_cat_topical_en.svg';
import catTwelveWeek from './svg/tip_text_cat_twelveweek_en.svg';
import dogChewable from './svg/tip_text_dog_chewable_en.svg';
import dogFlea from './svg/tip_text_dog_flea_en.svg';
import dogStethoscope from './svg/tip_text_dog_stethoscope_en.svg';
import dogTemperature from './svg/tip_text_dog_temperature_en.svg';
import dogTick from './svg/tip_text_dog_tick_en.svg';
import dogTwelveWeek from './svg/tip_text_dog_twelveweek_en.svg';

export default {
  cat: {
    cat: catCat,
    flea: catFlea,
    stethoscope: catStethoscope,
    tick: catTick,
    topical: catTopical,
    twelveweek: catTwelveWeek,
  },
  dog: {
    chewable: dogChewable,
    flea: dogFlea,
    stethoscope: dogStethoscope,
    temperature: dogTemperature,
    tick: dogTick,
    twelveweek: dogTwelveWeek,
  },
};
