import React, { useContext } from 'react';
import { get, isNull } from 'lodash';
import PhoneButton from './PhoneButton';
import buttonIcon from '../../images/svg/Product_Heart_no_shadow.svg';
import ButtonActionContext from '../../contexts/PhoneButtonActionContext';
import ScreenContext from '../../contexts/ScreenContext';
import bStyles from '../../css_modules/ui_elements/PhoneButton.module.css';
import { SCREEN_GAME, SCREEN_HOME } from '../../constants/constants';

function SelectButton() {
  const actionContext = useContext(ButtonActionContext),
    clickHandlers = get(actionContext, 'clickHandlers'),
    labelText = get(actionContext, 'config.common.select'),
    { currentScreen } = useContext(ScreenContext),
    noHandlers = isNull(clickHandlers),
    extraClasses = [];

  if (noHandlers && currentScreen === SCREEN_HOME) {
    extraClasses.push(bStyles.centreAnimation);
  } else if (noHandlers && currentScreen === SCREEN_GAME) {
    extraClasses.push(bStyles.centre);
  }

  return (
    <PhoneButton
      id="homePhoneHeartButton"
      buttonClass="select"
      labelText={labelText}
      icon={buttonIcon}
      showLabel={!noHandlers}
      extraClasses={extraClasses}
    />
  );
}

export default SelectButton;
