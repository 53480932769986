import back from './svg/Card_Back.svg';
import cat from './svg/Card_Cat.svg';
import chewable from './svg/Card_Chewable.svg';
import flea from './svg/Card_Flea.svg';
import stethoscope from './svg/Card_Stethoscope.svg';
import tick from './svg/Card_Tick.svg';
import topical from './svg/Card_Topical.svg';

export default [
  back,
  cat,
  chewable,
  flea,
  stethoscope,
  tick,
  topical,
];
