import React from 'react';
import blankCard from '../../images/svg/Phone_Card.svg';
import pStyles from '../../css_modules/ui_elements/Phone.module.css';

function BackgroundStack() {
  return (
    <div className={pStyles.backgroundStack}>
      <img src={blankCard} alt="Background profile card" className={pStyles.cardOne} />
      <img src={blankCard} alt="Background profile card" className={pStyles.cardTwo} />
    </div>
  );
}

export default BackgroundStack;
