import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { camelCase, isNull, get } from 'lodash';
import bStyles from '../../css_modules/ui_elements/PhoneButton.module.css';
import { randomKey } from '../../utilities/functions';
import ButtonActionContext from '../../contexts/PhoneButtonActionContext';
import ScreenContext from '../../contexts/ScreenContext';
import { SCREEN_STATE_TEARDOWN, SCREEN_STATE_RESET } from '../../constants/constants';

function PhoneButton(props) {
  const {
      id, labelText, buttonClass, icon, showLabel, extraClasses,
    } = props,
    classNamesArray = [bStyles.base, bStyles[buttonClass]],
    containerClassesArray = [bStyles.buttonContainer, bStyles[buttonClass]],
    actionContext = useContext(ButtonActionContext),
    clickHandlers = get(actionContext, 'clickHandlers'),
    { screenState } = useContext(ScreenContext),
    noHandlers = isNull(clickHandlers);

  let clickHandler,
    label = null,
    labelId = '';

  if (noHandlers) {
    clickHandler = null;
    classNamesArray.push(bStyles.disabled);
  } else {
    clickHandler = clickHandlers[buttonClass];
    if (screenState !== SCREEN_STATE_RESET && screenState !== SCREEN_STATE_TEARDOWN) {
      classNamesArray.push(bStyles.pulse);
    }
  }

  if (showLabel) {
    label = (
      <label id={labelId} htmlFor={id} className={bStyles.phoneButtonLabel}>
        {labelText}
      </label>
    );
    labelId = randomKey(camelCase(labelText));
  }

  if (extraClasses.length > 0) {
    extraClasses.forEach(
      (extraClass) => containerClassesArray.push(extraClass)
    );
  }

  const classNames = cx(classNamesArray),
    containerClasses = cx(containerClassesArray);

  return (
    <div className={containerClasses}>
      <button
        type="button"
        id={id}
        className={classNames}
        aria-labelledby={labelId}
        onClick={clickHandler}
        disabled={noHandlers}
      >
        <img src={icon} alt={labelText} />
      </button>
      {label}
    </div>
  );
}

PhoneButton.defaultProps = {
  showLabel: true,
  extraClasses: [],
};

PhoneButton.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  extraClasses: PropTypes.arrayOf(PropTypes.string),
};

export default PhoneButton;
