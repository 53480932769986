import React, { useContext } from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';
import merckLogoEn from '../../../images/svg/Logo_Merck_en.svg';
import merckLogoFr from '../../../images/svg/Logo_Merck_fr.svg';
import mStyles from '../../../css_modules/ui_elements/home/MerckLogo.module.css';
import ScreenContext from '../../../contexts/ScreenContext';
import {
  SCREEN_STATE_BUILD, SCREEN_STATE_GO_TO_GAME
} from '../../../constants/constants';

function MerckLogo() {
  const componentClassArray = [mStyles.merckLogoComponent, 'm-0', 'p-0p'],
    { screenState, selectedProfile, locale } = useContext(ScreenContext);

  if (isNull(selectedProfile) && screenState === SCREEN_STATE_BUILD) {
    componentClassArray.push(mStyles.fadeIn);
  } else if (!isNull(selectedProfile) && screenState === SCREEN_STATE_GO_TO_GAME) {
    componentClassArray.push(mStyles.fadeOut);
  }

  let merckLogo = merckLogoEn;

  if (locale === 'fr') {
    merckLogo = merckLogoFr;
  }

  const componentClasses = cx(componentClassArray);
  return (
    <div id="homeScreenMerckLogo" className={componentClasses}>
      <img src={merckLogo} alt="Merck Animal Health" className={mStyles.merck} />
    </div>
  );
}

export default MerckLogo;
