import React from 'react';
import cx from 'classnames';
import { isNull, has } from 'lodash';
import MatchGameContext from '../../../contexts/MatchGameContext';
import { TipContextProvider } from '../../../contexts/TipContext';
import tpStyles from '../../../css_modules/ui_elements/game/TipPopup.module.css';
import GradientCircleBackground from '../GradientCircleBackground';
import TipFlipper from './TipFlipper';
import {
  TIP_STATE_BUILD, TIP_STATE_DISPLAY, TIP_STATE_TEARDOWN
} from '../../../constants/constants';

class TipPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayState: TIP_STATE_BUILD,
      cardAssetPath: '',
      addButtons: false,
    };

    this.createCard = this.createCard.bind(this);
    this.doFlip = this.doFlip.bind(this);
    this.doClose = this.doClose.bind(this);
    this.unpauseGameCallback = this.unpauseGameCallback.bind(this);
    this.doAddButtons = this.doAddButtons.bind(this);

    this.closeTimeoutId = null;
    this.addButtonsTimeoutId = null;
  }

  componentDidMount() {
    const { tipType } = this.context;

    import(`../../../images/svg/Card_${tipType}.svg`).then(this.createCard);
  }

  componentWillUnmount() {
    this.clearTimeout('closeTimeoutId');
    this.clearTimeout('addButtonsTimeoutId');
  }

  doFlip() {
    const { config: { tips: { addButtonsTime } } } = this.context,
      { displayState } = this.state;

    if (displayState === TIP_STATE_BUILD) {
      this.setState({ displayState: TIP_STATE_DISPLAY });

      this.addButtonsTimeoutId = setTimeout(this.doAddButtons, addButtonsTime);
    }
  }

  doClose() {
    this.setState({ displayState: TIP_STATE_TEARDOWN }, this.unpauseGame);
  }

  doAddButtons() {
    const { showTip } = this.context;
    this.setState({ addButtons: true });
    this.clearTimeout('addButtonsTimeoutId');
    showTip();
  }

  clearTimeout(timeoutName) {
    if (has(this, timeoutName)) {
      if (!isNull(this[timeoutName])) {
        clearTimeout(this[timeoutName]);
        this[timeoutName] = null;
      }
    }
  }

  unpauseGame() {
    const { config: { unpauseTime } } = this.context;
    this.closeTimeoutId = setTimeout(this.unpauseGameCallback, unpauseTime);
  }

  unpauseGameCallback() {
    const { unpauseGame } = this.context;
    this.clearTimeout('closeTimeoutId');
    unpauseGame();
  }

  createCard(svgAsset) {
    const { default: assetPath } = svgAsset;

    this.setState({ cardAssetPath: assetPath });
  }

  render() {
    const { displayState, cardAssetPath, addButtons } = this.state,
      { tipType, animalType } = this.context,
      classNamesArray = [tpStyles.tipPopup];

    let card,
      buttons,
      flipped = false;

    if (displayState === TIP_STATE_BUILD) {
      classNamesArray.push(tpStyles.inAnimation);
    } else if (displayState === TIP_STATE_DISPLAY) {
      flipped = true;
    } else if (displayState === TIP_STATE_TEARDOWN) {
      classNamesArray.push(tpStyles.outAnimation);
      flipped = true;
    }

    if (cardAssetPath !== '') {
      card = <div className={tpStyles.card}><img src={cardAssetPath} alt="" /></div>;
    }

    if (addButtons) {
      buttons = (
        <>
          <button
            onClick={this.doClose}
            type="button"
            className={tpStyles.tipClickerOne}
          >
            Close Tip
          </button>
          <button
            onClick={this.doClose}
            type="button"
            className={tpStyles.tipClickerTwo}
          >
            Close Tip
          </button>
        </>
      );
    }

    const classNames = cx(classNamesArray);

    return (
      <div className={classNames}>
        <GradientCircleBackground className={tpStyles.gradientCircle} />
        {card}
        <TipContextProvider value={{ doFlip: this.doFlip, flipped }}>
          <TipFlipper type={tipType} animal={animalType} flipped={flipped} />
        </TipContextProvider>
        {buttons}
      </div>
    );
  }
}

TipPopup.contextType = MatchGameContext;

export default TipPopup;
