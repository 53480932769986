import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isNull } from 'lodash';
import blStyles from '../../css_modules/ui_elements/BravectoLogo.module.css';
import tpStyles from '../../css_modules/ui_elements/game/TipPopup.module.css';
import bravectoLogoEn from '../../images/svg/Logo_Bravecto_en.svg';
import twelveWeeksEn from '../../images/svg/Logo_12weeks_en.svg';
import upToTwelveWeeksEn from '../../images/svg/Logo_12week_UPTO_1asterisk_en.svg';
import bravectoLogoFr from '../../images/svg/Logo_Bravecto_fr.svg';
import twelveWeeksFr from '../../images/svg/Logo_12weeks_fr.svg';
import upToTwelveWeeksFr from '../../images/svg/Logo_12week_UPTO_1asterisk_fr.svg';
import ScreenContext from '../../contexts/ScreenContext';
import {
  SCREEN_HOME, SCREEN_GAME, ANIMAL_TYPE_DOG, ANIMAL_TYPE_CAT, SCREEN_STATE_BUILD,
  SCREEN_STATE_GO_TO_GAME
} from '../../constants/constants';

function BravectoLogo(props) {
  const {
      screenState, selectedProfile, currentScreen, locale,
    } = useContext(ScreenContext),
    componentStylesArray = [blStyles.bravectoLogoComponent, 'm-0', 'p-0'],
    twelveWeekClassesArray = [blStyles.protection],
    upToTwelveWeekClassesArray = [blStyles.protection],
    { inPopup } = props;

  let protectionLogos = null,
    bravectoLogo = bravectoLogoEn,
    twelveWeeks = twelveWeeksEn,
    upToTwelveWeeks = upToTwelveWeeksEn;

  if (locale === 'fr') {
    bravectoLogo = bravectoLogoFr;
    twelveWeeks = twelveWeeksFr;
    upToTwelveWeeks = upToTwelveWeeksFr;
  }

  if (currentScreen === SCREEN_HOME) {
    if (!isNull(selectedProfile) && screenState === SCREEN_STATE_GO_TO_GAME && inPopup === false) {
      componentStylesArray.push(blStyles.fadeOut);
    } else if (isNull(selectedProfile) && screenState === SCREEN_STATE_BUILD && inPopup === false) {
      componentStylesArray.push(blStyles.fadeIn);
    }
  } else if (currentScreen === SCREEN_GAME) {
    const { animalType } = selectedProfile;

    componentStylesArray.push(animalType);

    if (inPopup === false) {
      componentStylesArray.push(blStyles.game);

      if (screenState === SCREEN_STATE_BUILD) {
        componentStylesArray.push(blStyles.slideIn);
      }
    } else {
      componentStylesArray.push(tpStyles.logo);
      twelveWeekClassesArray.push(tpStyles.protection);
      upToTwelveWeekClassesArray.push(tpStyles.protection);
    }

    const twelveWeekClasses = cx(twelveWeekClassesArray),
      upToTwelveWeekClasses = cx(upToTwelveWeekClassesArray);

    if (animalType === ANIMAL_TYPE_DOG) {
      protectionLogos = <img src={twelveWeeks} className={twelveWeekClasses} alt="Twelve week-protection for dogs" />;
    } else if (animalType === ANIMAL_TYPE_CAT) {
      protectionLogos = <img src={upToTwelveWeeks} className={upToTwelveWeekClasses} alt="Twelve week-protection for cats" />;
    }
  }

  const componentStyles = cx(componentStylesArray);

  return (
    <div id="homeScreenBravectoLogo" className={componentStyles}>
      <img src={bravectoLogo} className={blStyles.bravecto} alt="Bravecto&reg;" />
      {protectionLogos}
    </div>
  );
}

BravectoLogo.defaultProps = {
  inPopup: false,
};

BravectoLogo.propTypes = {
  inPopup: PropTypes.bool,
};

export default BravectoLogo;
