import React from 'react';
import cx from 'classnames';
import { cloneDeep, isNull } from 'lodash';
import swStyles from '../../../css_modules/ui_elements/game/StopWatch.module.css';
import ScreenContext from '../../../contexts/ScreenContext';
import { SCREEN_STATE_BUILD } from '../../../constants/constants';

class StopWatch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timerOn: false,
      timerStart: 0,
      timerTime: 0,
    };

    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.getTime = this.getTime.bind(this);

    this.intervalId = null;
  }

  componentDidMount() {
    const { registerStopWatchHandlers } = this.context;

    registerStopWatchHandlers(this);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getTime() {
    const { timerTime } = this.state,
      seconds = (Math.floor(timerTime / 1000) % 60).toString().padStart(2, '0'),
      minutes = (Math.floor(timerTime / 60000) % 60).toString();

    return `${minutes}:${seconds}`;
  }

  startTimer() {
    const { timerTime } = this.state,
      newState = cloneDeep(this.state);

    newState.timerOn = true;
    newState.timerTime = timerTime;
    newState.timerStart = Date.now() - timerTime;

    if (!isNull(this.intervalId)) {
      clearTimeout(this.intervalId);
    }

    this.intervalId = setInterval(
      () => {
        const { timerStart: ntrvlTimerStart } = this.state,
          ntrvlNewState = cloneDeep(this.state);

        ntrvlNewState.timerTime = Date.now() - ntrvlTimerStart;
        this.setState(ntrvlNewState);
      },
      10
    );

    this.setState(newState);
  }

  stopTimer() {
    const newState = cloneDeep(this.state);

    newState.timerOn = false;
    this.clearInterval();
    this.setState(newState);
  }

  resetTimer() {
    const newState = cloneDeep(this.state);

    if (newState.timerOn) {
      newState.timerOn = false;
      this.clearInterval();
    }

    newState.timerTime = 0;
    newState.timerStart = 0;
    this.setState(newState);
  }

  clearInterval() {
    if (!isNull(this.intervalId)) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  render() {
    const time = this.getTime(),
      classNamesArray = [swStyles.stopWatch],
      { screenState } = this.context;

    if (screenState === SCREEN_STATE_BUILD) {
      classNamesArray.push(swStyles.inAnimation);
    }

    const classNames = cx(classNamesArray);

    return (
      <div className={classNames}>
        {time}
      </div>
    );
  }
}

StopWatch.contextType = ScreenContext;

export default StopWatch;
