import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import heart from '../../../images/svg/Product_Heart_no_shadow.svg';
import TipContent from './TipContent';
import tpStyles from '../../../css_modules/ui_elements/game/TipPopup.module.css';

function TipFlipper(props) {
  const { type, animal, flipped } = props,
    outerClassNamesArray = [tpStyles.tipFlipperOuter, tpStyles.tipFlipper];

  if (flipped) {
    outerClassNamesArray.push(tpStyles.flipped);
  }

  const outerClassNames = cx(outerClassNamesArray);

  return (
    <div className={outerClassNames}>
      <div className={tpStyles.tipFlipperInner}>
        <div className={tpStyles.tipFlipperFront}>
          <img src={heart} alt="" />
        </div>
        <div className={tpStyles.tipFlipperBack}>
          <TipContent type={type} animal={animal} />
        </div>
      </div>
    </div>
  );
}

TipFlipper.defaultProps = {
  flipped: false,
};

TipFlipper.propTypes = {
  type: PropTypes.string.isRequired,
  animal: PropTypes.string.isRequired,
  flipped: PropTypes.bool,
};

export default TipFlipper;
