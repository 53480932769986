import logo12WeekUpTo from './svg/Logo_12week_UPTO_1asterisk_fr.svg';
import logo12Weeks from './svg/Logo_12weeks_fr.svg';
import logoBravecto from './svg/Logo_Bravecto_fr.svg';
import logoMerck from './svg/Logo_Merck_fr.svg';

export default [
  logo12WeekUpTo,
  logo12Weeks,
  logoBravecto,
  logoMerck,
];
