import React, { useContext } from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';
import ScreenContext from '../../contexts/ScreenContext';
import bg from '../../images/svg/Background.svg';
import screenStyles from '../../css_modules/screens/Screen.module.css';
import { SCREEN_HOME, SCREEN_STATE_BUILD } from '../../constants/constants';

function BackgroundSwoosh() {
  const classes = [screenStyles.gameScreenBg],
    { selectedProfile, currentScreen, screenState } = useContext(ScreenContext);

  if (isNull(selectedProfile) && currentScreen === SCREEN_HOME
    && screenState === SCREEN_STATE_BUILD) {
    classes.push(screenStyles.slideIn);
  }

  const classNames = cx(classes);

  return (
    <img src={bg} alt="" className={classNames} />
  );
}

export default BackgroundSwoosh;
