import background from './svg/Background.svg';
import gradientCircle from './svg/Gradient_Circle.svg';
import productsCircle from './svg/Products_Circle.svg';
import phoneCard from './svg/Phone_Card.svg';
import phoneNext from './svg/Phone_Next_Button.svg';
import phoneShell from './svg/PhoneShell.svg';
import productHeartNoShadow from './svg/Product_Heart_no_shadow.svg';
import productHeart from './svg/Product_Heart_shadow.svg';
import productCats from './png/Product_Cats.png';
import productDogs from './png/Products_Dogs.png';

export default [
  background,
  gradientCircle,
  productsCircle,
  phoneCard,
  phoneNext,
  phoneShell,
  productHeartNoShadow,
  productHeart,
  productCats,
  productDogs,
];
