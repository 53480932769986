import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isNull, cloneDeep, set } from 'lodash';
import { randomKey } from '../../utilities/functions';
import pStyles from '../../css_modules/ui_elements/Phone.module.css';
import NextButton from './NextButton';
import SelectButton from './SelectButton';
import Profiles from './Profiles';
import { ButtonActionContextProvider } from '../../contexts/PhoneButtonActionContext';
import { ProfilesContextProvider } from '../../contexts/PhoneProfilesContext';
import ScreenContext from '../../contexts/ScreenContext';
import {
  SCREEN_HOME, SCREEN_GAME, SCREEN_STATE_BUILD, SCREEN_STATE_TEARDOWN, SCREEN_STATE_WAIT,
  SCREEN_STATE_GO_TO_GAME, SCREEN_STATE_RESET
} from '../../constants/constants';

class Phone extends React.Component {
  constructor(props) {
    const profiles = props.profiles.map(
      (profile) => set(profile, 'key', randomKey())
    );

    super(props);

    this.state = {
      profiles,
      noInitialAnimation: true,
    };

    this.nextProfile = this.nextProfile.bind(this);
    this.selectProfile = this.selectProfile.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { screenState } = this.context,
      { profiles } = this.props;

    if (screenState === SCREEN_STATE_RESET) {
      this.resetProfiles(profiles);
    }
  }

  resetProfiles(profilesFromProps) {
    const { profiles } = this.state,
      newProfiles = cloneDeep(profiles);

    newProfiles.sort(
      (profile1, profile2) => {
        const p1Index = profilesFromProps.findIndex(
            (profile) => profile.name === profile1.name
          ),
          p2Index = profilesFromProps.findIndex(
            (profile) => profile.name === profile2.name
          );

        if (p1Index < p2Index) {
          return -1;
        }

        if (p1Index > p2Index) {
          return 1;
        }

        return 0;
      }
    );

    this.setState({ profiles: newProfiles });
  }

  nextProfile() {
    const { profiles, noInitialAnimation } = this.state,
      firstProfile = profiles.shift(),
      { screenState } = this.context;

    if (screenState !== SCREEN_STATE_TEARDOWN) {
      this.resetAppTimeouts();

      profiles.push(firstProfile);

      let newState = { profiles };

      if (noInitialAnimation) {
        newState = { profiles, noInitialAnimation: false };
      }

      this.setState(newState);
    }
  }

  selectProfile() {
    const { profiles } = this.state,
      { currentScreen, selectProfile, screenState } = this.context;

    if (currentScreen === SCREEN_HOME && screenState !== SCREEN_STATE_TEARDOWN) {
      this.clearAppTimeouts();
      selectProfile(profiles[0]);
    }
  }

  clearAppTimeouts() {
    const { currentScreen, screenState } = this.context;

    if (currentScreen === SCREEN_HOME && screenState === SCREEN_STATE_BUILD) {
      const { clearBuildTimeout } = this.context;

      clearBuildTimeout();
    } else if (currentScreen === SCREEN_HOME && screenState === SCREEN_STATE_WAIT) {
      const { clearWaitTimeout } = this.context;

      clearWaitTimeout();
    }
  }

  resetAppTimeouts() {
    const { currentScreen, screenState } = this.context;

    if (currentScreen === SCREEN_HOME && screenState === SCREEN_STATE_BUILD) {
      const { clearBuildTimeout, setBuildTimeout } = this.context;

      clearBuildTimeout();
      setBuildTimeout();
    } else if (currentScreen === SCREEN_HOME && screenState === SCREEN_STATE_WAIT) {
      const { clearWaitTimeout, setWaitTimeout } = this.context;

      clearWaitTimeout();
      setWaitTimeout();
    }
  }

  render() {
    const classNames = [pStyles.phoneComponent, 'p-0', 'm-0'],
      { profiles, noInitialAnimation } = this.state,
      {
        selectedProfile, currentScreen, screenState, config,
      } = this.context;

    let clickHandlers = {
        next: this.nextProfile,
        select: this.selectProfile,
      },
      profilesProp = cloneDeep(profiles),
      noInitialAnimationProp = cloneDeep(noInitialAnimation);

    if (isNull(selectedProfile) && currentScreen === SCREEN_HOME
      && screenState === SCREEN_STATE_BUILD) {
      classNames.push(pStyles.slideIn);
    } else if (!isNull(selectedProfile) && currentScreen === SCREEN_HOME
      && screenState === SCREEN_STATE_GO_TO_GAME) {
      classNames.push(pStyles.goToGame);
    } else if (currentScreen === SCREEN_GAME) {
      classNames.push(pStyles.game);
    }

    if (!isNull(selectedProfile)) {
      clickHandlers = null;
      profilesProp = [selectedProfile];
      noInitialAnimationProp = true;
    }

    const componentClasses = cx(classNames),
      profilesContext = {
        profiles: profilesProp,
        noInitialAnimation: noInitialAnimationProp,
      },
      buttonActionContext = {
        clickHandlers,
        config,
      };

    return (
      <div id="phone" className={componentClasses}>
        <ButtonActionContextProvider value={buttonActionContext}>
          <NextButton />
          <SelectButton />
        </ButtonActionContextProvider>
        <ProfilesContextProvider value={profilesContext}>
          <Profiles />
        </ProfilesContextProvider>
      </div>
    );
  }
}

Phone.contextType = ScreenContext;

Phone.defaultProps = {
  profiles: [],
};

Phone.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.object
  ),
};

export default Phone;
